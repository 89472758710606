import React, { useState } from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {
  Container,
  Col,
  Row,
} from 'reactstrap';
import {
  Layout,
  DownloadPageIntroduction,
  FAQs,
  AppCarousel,
  DownloadBlurb,
} from '../components';

const WelcomeApp = () => {
  const [downloadClicked, setDownloadClicked] = useState(false);

  const onDownloadClicked = (buttonLocation) => {
    trackCustomEvent({
      category: 'RequestDownload',
      action: buttonLocation,
      label: 'landing-page-1',
    });
    setDownloadClicked(true);
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col lg={6}>
            <DownloadPageIntroduction onDownloadClicked={() => onDownloadClicked('SplashButton')} />
          </Col>
          <Col lg={6}>
            <AppCarousel />
          </Col>
        </Row>
      </Container>
      <DownloadBlurb
        downloadClicked={downloadClicked}
        onDownloadClicked={() => onDownloadClicked('GetStartedButton')}
      />
      <FAQs />
    </Layout>
  );
};

export default WelcomeApp;
